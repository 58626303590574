import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import './css/basic-home-style.css';

const BasicHomeContent = () => {
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const texts = [
        "''Since I started using ProspectNav, I've led my team in the number of meetings set''",
        "''The content created is 90-95% ready to go and helps me engage with my unengaged accounts''",
        "''I was on a PIP before I started using ProspectNav and this quarter, I got promoted to be an Account Executive''",
        "''ProspectNav cuts down the time it takes for me to do research and create personalized messaging''"
    ];

    const images = [
        "/demo1.png",
        "/demo2.png",
        "/demo3.png",
        "/demo4.png",
        "/demo5.png",
        "/demo6.png",
        "/demo7.png"
    ];

    useEffect(() => {
        const textInterval = setInterval(() => {
            setCurrentTextIndex(prevIndex => (prevIndex + 1) % texts.length);
        }, 7000); // Change text every 7 seconds

        return () => {
            clearInterval(textInterval);
        }; // Cleanup on unmount
    }, [texts.length]);

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <Container className='basic-home-container' style={{ marginTop: '0%' }}>
            <Row className="justify-content-center align-items-center">
                <Col xs={12} md={8} className='text-center'>
                    <div className='header-div'>
                        <img 
                            src="/microscopelogo.png" 
                            alt="Microscope Logo" 
                            className="microscope-logo"
                        />
                        <h1 className="prospectnav-title">ProspectNav</h1>
                    </div>
                </Col>
            </Row>

            <Row>
                <div className='additional-info-div'>
                    <p style={{ width: '100%', marginLeft: '0%', fontSize: '22px' }}>
                        The #1 Messaging Personalizer for BDRs & Sales Professionals <br />
                        ProspectNav provides sellers with research on accounts and personalized emails, call scripts, qualification questions & more to create opportunities
                    </p>
                </div>
            </Row>
            <Row>
                <div className='flow-div'>
                    <Row className='row' style={{ marginTop: '-40px' }}>
                        <Col md={3}>
                            <img src="/lightbulb1.png" alt="Lightbulb Icon" style={{ width: '30%', height: '30%', marginBottom: '10px' }} />
                            <p>Set 3x the meetings & spend more time reaching out to target customers</p>
                        </Col>
                        <Col md={1}>
                            <div className='right-arrow-div'>
                                &rarr;
                            </div>
                        </Col>
                        <Col md={3}>
                            <img src="/searchmagnifying2.png" alt="Magnifying Glass Icon" style={{ width: '30%', height: '30%', marginBottom: '10px' }} />
                            <p>Save 90% of the time spent researching accounts & personalizing messaging</p>
                        </Col>
                        <Col md={1}>
                            <div className='right-arrow-div'>
                                <b>&rarr;</b>
                            </div>
                        </Col>
                        <Col md={3}>
                            <img src="/chatbubble3.png" alt="Chat Bubble Icon" style={{ width: '30%', height: '30%', marginBottom: '10px' }} />
                            <p>Start sourcing opportunities for free, hit your number & get promoted by signing up</p>
                        </Col>
                    </Row>
                </div>
            </Row>
            <Row style={{ height: '50px' }}>
                {/* Empty section for spacing */}
            </Row>
            <Row>
                <div className='banner-div'>
                    <p>{texts[currentTextIndex]}</p>
                </div>
            </Row>
            <Row style={{ height: '50px' }}>
                {/* Empty section for spacing */}
            </Row>
            <Row className="justify-content-center">
            </Row>
            <Row className="justify-content-center align-items-center mt-4">
                <Col xs={2} className="text-center d-flex align-items-center justify-content-center">
                    <Button onClick={handlePrevImage} className="arrow-button" style={{ backgroundColor: '#00c3b1', borderColor: '#00c3b1' }}>&larr;</Button>
                </Col>
                <Col xs={8} className="text-center">
                    <img
                        className="d-block img-fluid"
                        src={images[currentImageIndex]}
                        alt={`Demo image ${currentImageIndex + 1}`}
                        style={{ width: '100%', height: 'auto' }}
                    />
                </Col>
                <Col xs={2} className="text-center d-flex align-items-center justify-content-center">
                    <Button onClick={handleNextImage} className="arrow-button" style={{ backgroundColor: '#00c3b1', borderColor: '#00c3b1' }}>&rarr;</Button>
                </Col>
            </Row>


            <Row className="justify-content-center">
                <Col md={8} className="text-center">
                    <div className='info-div' style={{ marginBottom: '20px', width: '75%', marginLeft: '15%', fontSize: '24px'}}>
                        <p>How ProspectNav Sources Opportunities</p>
                    </div>
                </Col>
            </Row>
            <Row className='align-items-center'>
                <Col md={6}>
                    <div className='text-box-div'>
                        <p>ProspectNav searches the web and recent investment documents for your target company’s top business objectives, mission, and upcoming initiatives. ProspectNav will provide you with this information and more such as company overviews, competitors, challenges, opportunities, and industry analysis to help you have ideal customer-centric conversations with prospects.</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <img src="/searchesweb.png" alt="Searches Web" style={{ width: '35%', height: '35%' }} />
                    </div>
                </Col>
            </Row>
            <Row className='align-items-center'>
                {/* Text Box - Will appear above image on mobile */}
                <Col md={6} className="order-mobile-1">
                    <div className='text-box-div'>
                        <p>Upon signing-up, ProspectNav uses its intelligence to identify your company's unique differentiators, value propositions, and customer success stories (which you can edit) to optimize messaging.</p>
                    </div>
                </Col>

                {/* Image - Will appear below text on mobile */}
                <Col md={6} className="order-mobile-2">
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <img src="/formimages.png" alt="Form Images" style={{ width: '70%', height: '70%', marginTop: '30px' }} />
                    </div>
                </Col>
            </Row>
            <Row className='align-items-center'>
                <Col md={6}>
                    <div className='text-box-div'>
                        <p>Working backwards from your target company’s information and yours, ProspectNav uses state of the art generative AI to create messaging that leads to you creating opportunities and getting promoted in a faster period of time.</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <img src="/peopleconnect.png" alt="People Connect" style={{ width: '35%', height: '35%' }} />
                    </div>
                </Col>
            </Row>
            <Row style={{ height: '50px' }}>
                {/* Empty section for spacing */}
            </Row>
            <Row className="justify-content-center mt-5 subscription-section">
                <Col xs={12} className="text-center">
                    <h2 className="subscription-title">Subscription Plans</h2>
                </Col>
                <Col xs={12} md={5} className="d-flex justify-content-center mb-3">
                    <div className="subscription-card free-plan">
                        <h3>Free</h3>
                        <p>10 accounts to get started w/ ProspectNav when signing up</p>
                        <p className="price">$0</p>
                    </div>
                </Col>
                <Col xs={12} md={5} className="d-flex justify-content-center mb-3">
                    <div className="subscription-card pro-plan">
                        <h3>Pro</h3>
                        <p>50 Accounts / Month</p>
                        <p className="old-price"><s>$20/mo</s></p>
                        <p className="price">$10/mo</p>
                    </div>
                </Col>
                <Col xs={12} className="text-center">
                    <p className="subscription-footer">
                        All Plans Include: Account Research, Email Drafts, Qualification Questions, Messaging Tips, & More
                    </p>
                </Col>
            </Row>

        </Container>
    );
}

export default BasicHomeContent;
