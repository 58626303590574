import { Container, Navbar } from 'react-bootstrap';
import SignInLink from './signInLink';

const Header = () => {
    return (
        <Navbar bg="dark" data-bs-theme="dark" style={{marginBottom: '40px' ,top:'0px', position:'sticky'}} >
            <Container>
                <Navbar.Brand style={{fontSize: '30px',  font: 'Poppins'}} href='/'>ProspectNav</Navbar.Brand>
                <SignInLink />
            </Container>
      </Navbar>
    );
}

export default Header;