import { React, useEffect, useState } from 'react';
import { axiosProspectNavInstance } from '../../../services/prospectNav/apiClientInitializer';
import { Row, Spinner } from 'react-bootstrap';
import './css/target-account-list-style.css'
import { showAlert } from '../../../reducers/alertReducer';
import { useDispatch } from 'react-redux';

const TargetAccountListComponent = ({ handleTargetCellClick, selectedAccount }) => {
    const dispatch = useDispatch();
    const [targetAccounts, setTargetAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState();

    const fetchTargetAccounts = async() => {
        setIsLoading(true);
        try {
            const response = await axiosProspectNavInstance.get('/targetAccount');
            setTargetAccounts(response.data.targetAccounts);
        } catch (error) {
            dispatch(showAlert({ message: 'Unknown Error Occurred. Please try refreshing the page. If the issue persist, contact us at prospectingnavigator@gmail.com'}))
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (!targetAccounts.includes(selectedAccount)) {
            fetchTargetAccounts();
        }
    }, [selectedAccount]);

    const generateTargetAccountListCells = () => {
        const cellList = targetAccounts.map((targetAccount, index) => {
            return (
                <div
                    className={`target-account-cell-div ${selectedAccount === targetAccount ? 'selected' : ''}`}
                    key={targetAccount}
                    onClick={handleTargetCellClick}
                >
                    <Row><text>{targetAccount}</text></Row>
                </div>
            );
        });
        return (
            <div className='scrollable-container'>
                {cellList}
            </div>
        );
    }

    return (
        <div className='target-account-list-div'>
            <div className='header-row'>
                <Row><b>Previous Accounts</b></Row>
            </div>
            {isLoading && <Spinner></Spinner>}
            {targetAccounts && generateTargetAccountListCells()}
        </div>
    );
}

export default TargetAccountListComponent;