import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { axiosProspectNavLoginInstance } from '../services/prospectNav/apiClientInitializer';

const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const retrievedAccessToken = localStorage.getItem('accessToken');
    const retrievedRefreshToken = localStorage.getItem('refreshToken');

    const refreshAccessToken = async (refreshToken) => {
      try {
        // Make an API call to refresh the access token using the refresh token
        const body = {
          refresh_token: refreshToken,
        };

        const response = await axiosProspectNavLoginInstance.post('/auth/refreshToken', body);
    
        if (response.status !== 200) {
          throw new Error('Failed to refresh access token');
        }
    
        // Extract the new access token from the response
        const access_token = await response.data.access_token;
    
        // Return the new access token
        return access_token;
      } catch (error) {
        // If an error occurs during the API call, throw it
        throw new Error('Error refreshing access token: ' + error.message);
      }
    };
      

    const verifyAccessToken = async (token) => {
      try {
        jwtDecode(token);
        setAccessToken(token);
      } catch (error) {
        // If verification fails, check if refresh token is valid
        if (retrievedRefreshToken) {
          try {
            // Make an API call to refresh the access token using the refresh token
            const newAccessToken = await refreshAccessToken(retrievedRefreshToken);
            localStorage.setItem('accessToken', newAccessToken);

            setAccessToken(accessToken);
          } catch (error) {
            // If refresh token is invalid, log the error and return null
            setAccessToken(null);
          }
        } else {
          // If no refresh token is available, return null
          setAccessToken(null);
        }
      }
    };

    if (retrievedAccessToken) {
      verifyAccessToken(retrievedAccessToken);
    } else {
      setAccessToken(null);
    }
  }, [accessToken]);
  return accessToken;
};

export default useAccessToken;
