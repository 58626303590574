import { React, useCallback, useEffect, useState } from 'react';
import { axiosProspectNavInstance } from '../../../services/prospectNav/apiClientInitializer';
import { Col, Row, Spinner } from 'react-bootstrap';
import './css/gen-result-content-style.css'
import Emails from './emails';
import { showAlert } from '../../../reducers/alertReducer';
import { useDispatch } from 'react-redux';

const GenerationResultContent = ({ account }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCol, setSelectedCol] = useState(0);

    const handleColClick = (colIndex) => {
        setSelectedCol(colIndex);
    };

    const columns = [
        "Top Business Objectives",
        "3 Emails",
        "Cold Call Script",
        "Qualification Questions",
        "LinkedIn Prospecting"
    ];

    const colIndexToDataKeyMapping = {
        0: "topObjectives",
        2: "coldCallScript",
        3: "qualificationQuestions",
        4: "linkedInRequest"
    }

    const fetchAccountContent = useCallback(async() => {
        setIsLoading(true);
        try {
            const response = await axiosProspectNavInstance.get(`/targetAccount/${encodeURIComponent(account)}`);
            setData(response.data);
        } catch (error) {
            dispatch(showAlert({ message: 'Unknown Error Occurred. Please try refreshing the page. If the issue persist, contact us at prospectingnavigator@gmail.com'}))
        }
        setIsLoading(false);
    }, [account]);

    useEffect(() => {
        fetchAccountContent();
    }, [account]);

    const getColumns = () => {
        return columns.map((col, index) => {
            return (
                <Col
                    key={index}
                    className={`custom-col text-center ${selectedCol === index ? 'selected' : ''}`}
                    onClick={() => handleColClick(index)}
                >
                    <b>{col}</b>
                </Col>
            );
        });
    }

    return (
        <div>
            {isLoading ?
                <Spinner></Spinner>
            :
                <div className='generation-result-div'>
                    <div className='generation-result-header-row'>
                        <Row md={10}>
                            {getColumns()}
                        </Row>
                    </div>
                    <div className='result-div'>
                        <Row className='content-row'>
                            {selectedCol === 1 ?
                                <Emails 
                                    email1={data.email_1}
                                    email2={data.email_2}
                                    email3={data.email_3}
                                />
                            :
                            <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontSize: 'large' }}>{data[colIndexToDataKeyMapping[selectedCol]]}</pre>
                            }
                        </Row>
                    </div>
                </div>
            }
        </div>
    );
}

export default GenerationResultContent;