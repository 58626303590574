import React, { useState } from 'react';
import { Card, Button, Container, Row, Col, Alert } from 'react-bootstrap';

const SubscriptionInfo = ({ plan }) => {
  const [showAlert, setShowAlert] = useState(false);

  const handleCancelClick = () => {
    setShowAlert(true);
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  return (
    <Container className="my-4">
      <Row>
        <Col sm={12}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Current Subscription</Card.Title>
              <Card.Text>
                <strong>Plan:</strong> {plan}
              </Card.Text>
              <Button variant="danger" onClick={handleCancelClick}>Cancel Subscription</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          {showAlert && (
            <Alert variant="warning" onClose={handleAlertClose} dismissible>
              <Alert.Heading>Are you sure you want to cancel your subscription?</Alert.Heading>
              <p>
                Cancelling your subscription will remove access to premium features. If you want to cancel your subscription, please email us at prospectingnavigator@gmail.com
              </p>
            </Alert>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SubscriptionInfo;
