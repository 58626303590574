import { React } from 'react';
import FormComponent from './previousWebsite/formComponent';
import useFirstName from '../../hooks/useFirstName';
import BasicHome from './basic/basicHome';
import GenerationResult from './generate/generationResult';

const Home = () => {
    const firstName = useFirstName();

    return (
        <div>{firstName ? <GenerationResult /> : <BasicHome />}</div>
    );
}

export default Home;