import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import './css/sign-in-styles.css';
import { Link } from 'react-router-dom';
import { axiosProspectNavLoginInstance } from '../../services/prospectNav/apiClientInitializer';
import Header from '../header/header';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../reducers/alertReducer';

const LoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            // Here you can add your logic to handle form submission
            const requestBody = {
                user_email: email,
                user_password: password,
            };

            const response = await axiosProspectNavLoginInstance.post('/auth/login', requestBody);
            localStorage.setItem('accessToken', response.data.access_token);
            localStorage.setItem('refreshToken', response.data.refresh_token);
            navigate('/')
        } catch (error) {
            dispatch(showAlert({ message: "Failed to sign in. Please make sure that the email and password entered are correct." }));
        }

        setIsLoading(false);
    };

    return (
        <div>
            <Header />
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={6} className='horizontal-center'>
                        <div className="login-form-container">
                            <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="fields"
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="fields"
                                />
                            </Form.Group>

                            <div className="submit-button">
                                <Button variant="primary" type="submit" className='sign-in-button' onClick={handleSubmit}>
                                    {isLoading ? <Spinner></Spinner> : <div>Sign In</div>}
                                </Button>
                            </div>
                            <div className='sign-up-link-parent'>
                                <Link className='sign-up-link' to={'/signup'}>Sign Up Instead</Link>
                            </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default LoginForm;
