import axios from "axios";
import { jwtDecode } from "jwt-decode";

const signRequest = async (config, baseURL) => {
    // Get the access token using the useAccessToken hook
    let accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    const refreshAccessToken = async (refreshToken) => {
        try {
            // Make an API call to refresh the access token using the refresh token
            const body = {
                refresh_token: refreshToken,
              };
      
              const response = await axiosProspectNavLoginInstance.post('/auth/refreshToken', body);
        
              if (response.status !== 200) {
                throw new Error('Failed to refresh access token');
              }
        
            // Extract the new access token from the response
        const access_token = await response.data.access_token;
        
            // Return the new access token
            return access_token;
        } catch (error) {
            // If an error occurs during the API call, throw it
            throw new Error('Error refreshing access token: ' + error.message);
        }
    };

    const fetchRefreshTokenIfAvailable = async() => {
        if (refreshToken) {
            try {
                // Make an API call to refresh the access token using the refresh token
                const newAccessToken = await refreshAccessToken(refreshToken);
                localStorage.setItem('accessToken', newAccessToken);

                accessToken = newAccessToken;
            } catch (error) {
                // If refresh token is invalid, log the error and return null
                console.error('Error refreshing access token:', error);
                accessToken = null;
            }
        } else {
            // If no refresh token is available, return null
            accessToken = null;
        }
    }

    const verifyAccessToken = async (token) => {
        try {
            const decodedToken = jwtDecode(token);
            if (decodedToken.exp <= (Date.now()/1000)) {
                await fetchRefreshTokenIfAvailable();
            }
        } catch (error) {
            // If verification fails, check if refresh token is valid
            await fetchRefreshTokenIfAvailable();
        }
    };

    if (accessToken) {
        verifyAccessToken(accessToken);
    } else {
        accessToken = null;
    }

    // If access token exists, add it to the request headers
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
  
    return config;
};

export const axiosProspectNavInstance = axios.create({
    baseURL: 'https://unqg5pldmd.execute-api.us-west-2.amazonaws.com/prod',
});

axiosProspectNavInstance.interceptors.request.use(async(config) => {
    return await signRequest(config, config.baseURL);
}, (error) => {
    return Promise.reject(error);
});

export const axiosProspectNavLoginInstance = axios.create({
    baseURL: 'https://unqg5pldmd.execute-api.us-west-2.amazonaws.com/prod',
});