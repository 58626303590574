import { React, useState } from 'react';
import { Dropdown, Nav, Navbar } from 'react-bootstrap';
import useFirstName from '../../hooks/useFirstName';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const SignInLink = () => {
    const navigator = useNavigate();
    const firstName = useFirstName();
    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleLogout = () => {
        localStorage.clear();
        navigator('/signin'); 
    };

    const handleSettings = () => {
        navigator('/settings')
    }

    return (
        <Navbar.Collapse className="justify-content-end">
            {firstName ? 
                // <Navbar.Text>
                //     {firstName} <span><FontAwesomeIcon icon={faChevronDown} /></span>
                // </Navbar.Text>
                <Dropdown show={showDropdown} onToggle={toggleDropdown}>
                    <Dropdown.Toggle as="div" id="dropdown-custom-components">
                        <Navbar.Text onClick={toggleDropdown} style={{ cursor: 'pointer' }}>
                            {firstName} <FontAwesomeIcon icon={faChevronDown} />
                        </Navbar.Text>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={handleSettings}>Settings</Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            :
                <Nav>
                    <Link to={'/signin'} style={{color: '#00c3b1'}}>Sign In/Sign Up</Link>
                </Nav>
            }
        </Navbar.Collapse>
    );
}

export default SignInLink;