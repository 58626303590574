import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideAlert } from '../reducers/alertReducer';
import { Alert } from 'react-bootstrap';

const AlertBanner = () => {
    const alert = useSelector((state) => state.alert);
    const dispatch = useDispatch();

    return (
        alert.show && (
            <Alert
                variant={alert.variant}
                onClose={() => dispatch(hideAlert())}
                dismissible
            >
                {alert.message}
            </Alert>
        )
    );
};

export default AlertBanner;
