import React from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const SubscriptionCards = () => {
  const navigator = useNavigate();
  const subscriptions = [
    { name: 'Hit My Numbers Plan', price: '$10/month' },
  ];

  const handleSubscribe = () => {
    navigator('/checkout');
  }

  return (
    <Container className="my-4">
      <Row>
        {subscriptions.map((sub, index) => (
          <Col key={index} sm={12} md={6}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>{sub.name}</Card.Title>
                <Card.Text>{sub.price}</Card.Text>
                <Card.Text>Monthly Subscription</Card.Text>
                <Button variant="primary" onClick={handleSubscribe}>Subscribe</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default SubscriptionCards;
