import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { React } from 'react';

import './css/checkout-styles.css';
import { Row } from 'react-bootstrap';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const base_url = window.location.origin;
    const url = window.location.protocol + "//" + window.location.host;

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.origin + "/create-subscription",
      },
    });


    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      // console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <div className='stripe-payment-element-wrapper'>
      <form onSubmit={handleSubmit}>
        <Row>
          <PaymentElement id='payment-element'/>
        </Row>
        <Row className='pay-button-row '>
          <button disabled={!stripe} className='pay-button '>Pay</button>
        </Row>
      </form>
    </div>
  )
};

export default CheckoutForm;
