import { showAlert } from "../../../../reducers/alertReducer";
import { axiosProspectNavInstance } from "../../../../services/prospectNav/apiClientInitializer";

export const generateContentForTarget = async(target, persona, dispatch) => {
    try {
        const requestBody = {
            target_company: target,
            persona: persona,
        }

        const objectivesResponse = await axiosProspectNavInstance.post('/generate/objectives', requestBody);
        const objectives = objectivesResponse.data.topObjectives;

        requestBody['objectives'] = objectives;

        const emailResponse = await axiosProspectNavInstance.post('/generate/emails', requestBody);
        const email1 = emailResponse.data.email1;
        const email2 = emailResponse.data.email2;
        const email3 = emailResponse.data.email3;

        requestBody['email1'] = email1;
        requestBody['email2'] = email2;
        requestBody['email3'] = email3;

        const coldCallScriptResponse = await axiosProspectNavInstance.post('/generate/coldCallScript', requestBody);
        const coldCallScript = coldCallScriptResponse.data.coldCallScript;
        const qualificationQuestions = coldCallScriptResponse.data.qualificationQuestions;

        requestBody['coldCallScript'] = coldCallScript;
        requestBody['qualificationQuestions'] = qualificationQuestions;

        const linkedInRequestResponse = await axiosProspectNavInstance.post('/generate/linkedInRequest', requestBody);

        return linkedInRequestResponse;
    } catch (error) {
        dispatch(showAlert({ message: 'Unknown Error Occurred. Please try refreshing the page. If the issue persist, contact us at prospectingnavigator@gmail.com'}))
    }
}