import React from 'react';

const PersonalInfo = () => {
  return (
    <div>
      <h2>Personal Info</h2>
      <p>Content for Personal Info...</p>
    </div>
  );
};

export default PersonalInfo;