import React, { useEffect, useState } from 'react';
import CheckoutFromWrapper from '../checkout/CheckoutFromWrapper';

import './css/subscription-styles.css'
import SubscriptionCards from './subscriptionCards';
import SubscriptionInfo from './subscriptionInfo';
import useUserId from '../../hooks/useUserId';
import { axiosProspectNavInstance } from '../../services/prospectNav/apiClientInitializer';
import { Row, Spinner } from 'react-bootstrap';
import { showAlert } from '../../reducers/alertReducer';
import { useDispatch } from 'react-redux';

const Subscription = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [subscribed, setSubscribed] = useState(false);
  const [tokens, setTokens] = useState(0);
  const userId = useUserId();

  const getSubscriptionInfo = async() => {
    try {
      const response = await axiosProspectNavInstance.get(`/user/${userId}/subscription`);
      if (response.data.tier == 'BASIC') {
        setSubscribed(true);
      }
      setTokens(response.data.targetLeft);
    } catch(error) {
      dispatch(showAlert({ message: 'Unknown Error Occurred. Please try refreshing the page. If the issue persist, contact us at prospectingnavigator@gmail.com'}))
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getSubscriptionInfo();
  }, []);

  const getContentToShow = () => {
    return subscribed ? <SubscriptionInfo plan={'Hit My Numbers'}/> : <SubscriptionCards />;
  }

  return (
    <div className='subscription-container'>
      <h2>Subscription</h2>
      {isLoading ? <Spinner></Spinner> : getContentToShow()}
      <Row className='tokens-left-text'>
        You have {tokens} tokens left. One token is used to generate prospecting content for 1 target company.
      </Row>
    </div>
  );
};

export default Subscription;