// alertSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const alertSlice = createSlice({
    name: 'alert',
    initialState: {
        show: false,
        message: '',
        variant: 'danger',
    },
    reducers: {
        showAlert: (state, action) => {
            state.show = true;
            state.message = action.payload.message;
            state.variant = action.payload.variant || 'danger';
        },
        hideAlert: (state) => {
            state.show = false;
            state.message = '';
            state.variant = 'danger';
        },
    },
});

export const { showAlert, hideAlert } = alertSlice.actions;

export default alertSlice.reducer;
