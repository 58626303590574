import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { axiosProspectNavLoginInstance } from '../services/prospectNav/apiClientInitializer';
import useAccessToken from './useAccessToken';

const useUserId = () => {
  const [userId, setUserId] = useState(null);
  const accessToken = useAccessToken();

  useEffect(() => {

    if (accessToken) {
      const newDecodedToken = jwtDecode(accessToken);
      setUserId(newDecodedToken.user_id);
    } else {
      setUserId(null);
    }
  }, [accessToken]);
  return userId;
};

export default useUserId;