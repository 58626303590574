import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { axiosProspectNavInstance } from '../../services/prospectNav/apiClientInitializer';
import useUserId from '../../hooks/useUserId';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../../reducers/alertReducer';
import { useDispatch } from 'react-redux';

export const CreateSubscriptionLoading = () => {
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const userId = useUserId();
    const [subscribed, setSubscribed] = useState(false);

    const createSubscriptionInDB = async () => {
        const requestBody = {
            subscription_tier: 'BASIC',
            target_accounts_number: 50
        }
        try {
            const response = await axiosProspectNavInstance.post(`/user/${userId}/subscription`, requestBody);
            navigator('/')
        } catch (error) {
            dispatch(showAlert({ message: 'Unknown Error Occurred. Please try refreshing the page. If the issue persist, contact us at prospectingnavigator@gmail.com'}))
        }
    };

    useEffect(() => {
        if (!subscribed && userId) {
            setSubscribed(true);
            createSubscriptionInDB();
        }
    }, [subscribed, userId]);
  return (
    <div><Spinner></Spinner></div>
  )
}
