import { React } from 'react';
import Header from '../../header/header';
import './css/basic-home-style.css'
import BasicHomeContent from './basicHomeContent';

const BasicHome = () => {
    return (
        <div>
            <Header />
            <BasicHomeContent />
        </div>
    );
};

export default BasicHome;