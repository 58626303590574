// CompanyInfo.js
import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap';

import './css/company-info-styles.css';
import useUserId from '../../hooks/useUserId';
import { axiosProspectNavInstance } from '../../services/prospectNav/apiClientInitializer';
import { showAlert } from '../../reducers/alertReducer';
import { useDispatch } from 'react-redux';

const CompanyInfo = () => {
    const userId = useUserId();
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [companyData, setCompanyData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [formData, setFormData] = useState(null);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setFormData(companyData);
    };

    const handleSaveClick = async() => {
        setCompanyData(formData);
        setIsLoading(true);
        try {
            const response = await axiosProspectNavInstance.put(`/user/${encodeURIComponent(userId)}`, formData);
        } catch (error) {
            dispatch(showAlert({ message: 'Unknown Error Occurred. Please try refreshing the page. If the issue persist, contact us at prospectingnavigator@gmail.com'}))
        }
        setIsEditing(false);
        setIsLoading(false);
    };

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const fetchData = async () => {
        try {
            const response = await axiosProspectNavInstance.get(`/user/${encodeURIComponent(userId)}`);
            setCompanyData(response.data);
            setFormData(response.data);
        } catch (error) {
            dispatch(showAlert({ message: 'Unknown Error Occurred. Please try refreshing the page. If the issue persist, contact us at prospectingnavigator@gmail.com'}))
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (userId && !companyData) {
            fetchData();
        }
    }, [userId]);

    return (
        <div className='company-info'>
            <h2>Company Info</h2>
            {!isLoading ? 
                <div>
                    <div className='company-info-form-container'>
                        <Form>
                            <Form.Group as={Row} controlId="company_name" className='form-item'>
                                <Form.Label column sm={2}>
                                    Company Name:
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control
                                        type="text"
                                        name="company_name"
                                        value={formData.company_name}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                        className='form-control-field'
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="company_info" className='form-item'>
                                <Form.Label column sm={2}>
                                    Company Overview:
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control
                                        as="textarea"
                                        name="company_info"
                                        value={formData.company_info}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                        className='form-control-field'
                                        rows={10}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="company_links" className='form-item'>
                                <Form.Label column sm={2}>
                                    Company Links:
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control
                                        type="text"
                                        name="company_links"
                                        value={formData.company_links}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                        className='form-control-field'
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="value_props" className='form-item'>
                                <Form.Label column sm={2}>
                                    Top Value Propositions:
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control
                                        as="textarea"
                                        name="value_props"
                                        value={formData.value_props}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                        className='form-control-field'
                                        rows={10}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="case_studies" className='form-item'>
                                <Form.Label column sm={2}>
                                    Case Studies:
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control
                                        as="textarea"
                                        name="case_studies"
                                        value={formData.case_studies}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                        className='form-control-field'
                                        rows={10}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="additional_info" className='form-item'>
                                <Form.Label column sm={2}>
                                    Additional Relevant Information:
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control
                                        as="textarea"
                                        name="additional_info"
                                        value={formData.additional_info}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                        className='form-control-field'
                                        rows={10}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="user_title" className='form-item'>
                                <Form.Label column sm={2}>
                                    User Title:
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control
                                        type="text"
                                        name="user_title"
                                        value={formData.user_title}
                                        onChange={handleChange}
                                        disabled={!isEditing}
                                        className='form-control-field'
                                    />
                                </Col>
                            </Form.Group>
                        </Form>
                    </div>
                    <div className='button-container'>
                        {isEditing ? 
                            <div className='in-edit-mode-buttons'>
                                <Button
                                    variant="primary"
                                    onClick={handleSaveClick}
                                    className='button'
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={handleCancelClick}
                                    className='button secondary'
                                >
                                    Cancel
                                </Button>
                            </div>
                        : 
                            <div className='in-view-mode-buttons'>
                                <Button
                                    variant="primary"
                                    onClick={handleEditClick}
                                    className='button edit-button'
                                >
                                    Edit
                                </Button>
                            </div>
                        }
                    </div>
                </div>
            : <Spinner></Spinner>}
        </div>
    );
};

export default CompanyInfo;
