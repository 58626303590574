import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import './css/sign-up-style.css';
import { axiosProspectNavLoginInstance } from '../../services/prospectNav/apiClientInitializer';
import Header from '../header/header';
import { FontAwesomeIcon } from '@fortawesome/fontawesome-free';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { showAlert } from '../../reducers/alertReducer';
import { useDispatch } from 'react-redux';

const LoginForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [title, setTitle] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyInfo, setCompanyInfo] = useState('');
    const [relevantLinks, setRelevantLinks] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            // Here you can add your logic to handle form submission
            const requestBody = {
                user_email: email,
                user_password: password,
                first_name: firstName,
                last_name: lastName,
                user_company_name: companyName,
                user_title: title,
                user_company_info: companyInfo,
                user_company_links: relevantLinks,
            };

            const response = await axiosProspectNavLoginInstance.post('/auth/signup', requestBody);
            localStorage.setItem('accessToken', response.data.access_token);
            localStorage.setItem('refreshToken', response.data.refresh_token);
            navigate('/')
        } catch (error) {
            dispatch(showAlert({ message: 'Unknown Error Occurred. Please try refreshing the page. If the issue persist, contact us at prospectingnavigator@gmail.com'}))
        }

        setIsLoading(false);
    };

    return (
        <div>
            <Header />
            <Container>
                <Form onSubmit={handleSubmit} className='sign-up-form'>
                    <Row className="fields-row">
                        <Col xs={12} md={6}>
                            <div className="signup-form-container">
                                <h2 className='col-title'>Personal Info</h2>
                                <Form.Group controlId="formFirstName">
                                    <Form.Control
                                    type="text"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    className="fields"
                                    />
                                </Form.Group>

                                <Form.Group controlId="formLastName">
                                    <Form.Control
                                    type="text"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    className="fields"
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="fields"
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="fields"
                                    />
                                </Form.Group>

                                <Form.Group controlId="formTitle">
                                    <Form.Control
                                    type="text"
                                    placeholder="Title (for Messaging)"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    className="fields"
                                    />
                                </Form.Group>
                            </div>
                        </Col>

                        <Col xs={12} md={6} className='horizontal-center'>
                            <div className="signup-form-container">
                                <h2 className='col-title'>Company Info</h2>
                                <Form.Group controlId="formCompanyName">
                                    <Form.Control
                                    type="text"
                                    placeholder="Company Name"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    className="fields"
                                    />
                                </Form.Group>

                                <Form.Group controlId="formCompanyLinks">
                                    <Form.Control
                                    type="text"
                                    placeholder="Company Website"
                                    value={relevantLinks}
                                    onChange={(e) => setRelevantLinks(e.target.value)}
                                    className="fields"
                                    />
                                </Form.Group>

                                {/* Bubble Textbox for Additional Information */}
                                <div className="bubble-textbox">
                                    ProspectNav uses its intelligence to identify your company's unique differentiators, value propositions, and customer success stories (which you can edit in settings) to optimize messaging.
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='button-row'>
                        <div className="submit-button">
                            <Button variant="primary" type="submit" className='sign-up-button' onClick={handleSubmit}>
                                <div>
                                Sign-Up & Set Meetings w/ ProspectNav
                                </div>
                            </Button>
                        </div>
                    </Row>
                </Form>
            </Container>
        </div>
    );
};

export default LoginForm;
