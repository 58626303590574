import React from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import './css/emails-style.css'

const Emails = ({ email1, email2, email3 }) => {
  return (
    <div className='email-div'>
        <Tabs
            defaultActiveKey="email1"
            id="results-tabs"
            className="emailTabs"
            fill
        >
            <Tab eventKey='email1' title='Email 1' >
                <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontSize: 'large' }}>
                    {email1}
                </pre>
            </Tab>

            <Tab eventKey='email2' title='Email 2' >
                <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontSize: 'large' }}>
                    {email2}
                </pre>
            </Tab>

            <Tab eventKey='email3' title='Email 3' >
                <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontSize: 'large' }}>
                    {email3}
                </pre>
            </Tab>
        </Tabs>
    </div>
  )
}

export default Emails;
