import { Elements } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react'
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import { axiosProspectNavInstance } from '../../services/prospectNav/apiClientInitializer';

import './css/checkout-styles.css'
import Header from '../header/header';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../reducers/alertReducer';

const CheckoutFromWrapper = () => {
  const [clientSecret, setClientSecret] = useState(undefined);
  const dispatch = useDispatch();

  const stripePromise = loadStripe("pk_live_51PAagvAcjcytvybd5UJmxDFub0ckjCxNVPkjVxMAsNfEmIkbz1R95sPDuELi40dVfEjA5eMnc5EEIU0H7WPypCnS00dqiG05c3");

  const appearance = {
    theme: 'flat',
    variables: {
      colorPrimary: '#00c3b1',
      colorBackground: 'white',
      colorText: '#00c3b1',
      borderColor: 'white'
    }
  }

  const createIntent = async() => {
    try {
      const requestBody = {
        payment_amount: 1000,
      }

      const response = await axiosProspectNavInstance.post('/subscription/intent', requestBody)

      setClientSecret(response.data.client_secret);
    } catch (error) {
      dispatch(showAlert({ message: "Failed to submit a payment. Please try again or If the issue persist, contact us at prospectingnavigator@gmail.com" }));
    }
  }

  useEffect(() => {
    createIntent();
  }, [])

  return (
    <div>
      {!!clientSecret &&
        <Elements stripe={stripePromise} options={{ clientSecret: clientSecret, appearance: appearance }}>
          <CheckoutForm />
        </Elements>
      }
    </div>
  )
};

export default CheckoutFromWrapper;
