import './App.css';
import AWS from 'aws-sdk';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignIn from './modules/signin/signIn';
import SignUp from './modules/signup/signUp';

import '@fortawesome/fontawesome-free/css/all.css'; // Ensure you are importing the correct CSS file based on your package
import Home from './modules/home/home';
import CheckoutFromWrapper from './modules/checkout/CheckoutFromWrapper';
import { Settings } from './modules/settings/Settings';
import { CreateSubscriptionLoading } from './modules/settings/createSubscriptionLoading';
import { configureStore } from '@reduxjs/toolkit';
import alertReducer from './reducers/alertReducer';
import { Provider } from 'react-redux';
import AlertBanner from './components/alartBanner';

AWS.config.update({
  region: 'us-west-2',
  accessKeyId: 'AKIAVRUVUCF2N7FZSIJO',
  secretAccessKey: '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',
});

export let store = undefined;
export const getStore = () => {
  if (!store) {
    store = configureStore({
      reducer: {
        alert: alertReducer,
      },
    });
  }

  return store;
}

const App = () => {
  store = getStore();

  return (
    <Provider store={store}>
      <Router>
        <div style={{backgroundColor: '#000e1f', minHeight: '100vh'}}>
          <AlertBanner />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path='/signin' element={<SignIn />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/checkout' element={<CheckoutFromWrapper />} />
            <Route path='/settings' element={<Settings />} />
            <Route path='/create-subscription' element={<CreateSubscriptionLoading/>} />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
