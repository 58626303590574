import React from 'react'
import { Form, Spinner } from 'react-bootstrap';
import './css/generate-modal-style.css';

const GenerateModalContent = ({ targetName, setTargetName, persona, setPersona, generatingContent }) => {
  return (
    <div>
        {generatingContent ?
            <div className='text-center'>
                <Spinner></Spinner>
                <div>Generating Content might take a couple of minutes...</div>
            </div>
        :
            <div className='generate-content-fields-container'>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control
                            type="text"
                            placeholder="Enter Target Account Name"
                            value={targetName}
                            onChange={(e) => setTargetName(e.target.value)}
                            className="fields"
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Control
                            type="text"
                            placeholder="Enter Persona"
                            value={persona}
                            onChange={(e) => setPersona(e.target.value)}
                            className="fields"
                        />
                    </Form.Group>
                </Form>
            </div>
        }
    </div>
  )
}

export default GenerateModalContent;
