import React, { useState } from 'react'
import Header from '../header/header'
import { Col, Container, Row, Stack } from 'react-bootstrap'

import './css/settings-styles.css'
import PersonalInfo from './personalInfo'
import CompanyInfo from './companyInfo'
import Subscription from './subscription'

export const Settings = () => {
    const [selectedComponent, setSelectedComponent] = useState('CompanyInfo');

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'CompanyInfo':
        return <CompanyInfo />;
      case 'Subscription':
        return <Subscription />;
      default:
        return <PersonalInfo />;
    }
  };

  return (
    <div>
      <Header />
      <Container>
        <Row>
          <Col md={2}>
            <Stack className='settings-stack'>
              <div className='settings-stack-item' onClick={() => setSelectedComponent('CompanyInfo')}>Company Info</div>
              <div className='settings-stack-item' onClick={() => setSelectedComponent('Subscription')}>Subscription</div>
            </Stack>
          </Col>
          <Col md={10}>
            {renderComponent()}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
