import { React, useEffect, useReducer, useState } from 'react'
import Header from '../../header/header';
import { Button, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import TargetAccountListComponent from './targetAccountListComponent';
import GenerationResultContent from './generationResultContent';
import GenerationContent from './generationContent';

import './css/gen-result-styles.css'
import './css/target-account-list-style.css'
import './css/generate-modal-style.css'
import GenerateModalContent from './generateModalContent';
import { axiosProspectNavInstance } from '../../../services/prospectNav/apiClientInitializer';
import { generateContentForTarget } from './utils/generateRequest';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../../reducers/alertReducer';

const GenerationResult = () => {
    const dispatch = useDispatch();
    const [cellSelected, setCellSelected] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [showGenerateModal, setShowGenerateModal] = useState(true);
    const [targetAccountName, setTargetAccountName] = useState('');
    const [persona, setPersona] = useState('');
    const [generatingContent, setGeneratingContent] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [targetAccounts, setTargetAccounts] = useState([]);

    const handleTargetCellClick = (event) => {
        event.preventDefault();
        setSelectedAccount(event.target.textContent);
        setCellSelected(true);
    }

    const resetModalState = () => {
        setTargetAccountName('');
        setPersona('');
        setShowGenerateModal(false);
    }

    const handleModalClose = () => {
        resetModalState();
    }

    const openModal = () => {
        setShowGenerateModal(true);
    }

    const handleModalSubmit = async() => {
        setGeneratingContent(true);

        try {
            const response = await generateContentForTarget(targetAccountName, persona, dispatch);
        } catch (error) {
            dispatch(showAlert({ message: `Failed to generate content for ${targetAccountName}. Please try again. If the issue persist, contact us at prospectingnavigator@gmail.com` }))
        }

        setGeneratingContent(false);
        setSelectedAccount(targetAccountName);
        resetModalState();
    }

    const fetchTargetAccounts = async() => {
        setIsLoading(true);
        try {
            const response = await axiosProspectNavInstance.get('/targetAccount');
            setTargetAccounts(response.data.targetAccounts);
        } catch (error) {
            dispatch(showAlert({ message: 'Unknown Error Occurred. Please try refreshing the page. If the issue persist, contact us at prospectingnavigator@gmail.com'}))
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (!selectedAccount) {
            fetchTargetAccounts();
            if (targetAccounts && targetAccounts.length > 0) {
                setSelectedAccount(targetAccounts[0]);
                setCellSelected(true);
            }
        }
        else if (!targetAccounts.includes(selectedAccount)) {
            fetchTargetAccounts();
        }
    }, [selectedAccount, targetAccounts]);

    return (
        <div>
            <Header />
            <Container className='generation-result-main-container'>
                <div className='generate-row-div'>
                    <Row
                        className='generate-row'
                        onClick={openModal}
                    >
                        Generate
                    </Row>
                </div>
                <Row style={{marginTop: '30px'}}>
                    <Col md={3}>
                        <TargetAccountListComponent
                            handleTargetCellClick={handleTargetCellClick}
                            selectedAccount={selectedAccount}
                        />
                    </Col>
                    <Col md={9}>
                        {cellSelected ? 
                            <GenerationResultContent 
                                account={selectedAccount}
                            /> 
                        :
                            <GenerationContent />
                        }
                    </Col>
                </Row>
            </Container>
            <Modal show={showGenerateModal} onHide={handleModalClose} className='modal-container'>
                <div className='modal-header-container'>
                    <Modal.Header closeButton>
                        <Modal.Title>Generate Prospecting Content</Modal.Title>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <GenerateModalContent
                        generatingContent={generatingContent}
                        targetName={targetAccountName}
                        setTargetName={setTargetAccountName}
                        persona={persona}
                        setPersona={setPersona}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={handleModalSubmit}
                        disabled={generatingContent}
                        className='modal-generate-button'
                    >
                        Generate
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default GenerationResult;