import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { axiosProspectNavLoginInstance } from '../services/prospectNav/apiClientInitializer';

const useFirstName = () => {
  const [firstName, setFirstName] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    const refreshAccessToken = async (refreshToken) => {
      try {
        // Make an API call to refresh the access token using the refresh token
        const body = {
          refresh_token: refreshToken,
        };

        const response = await axiosProspectNavLoginInstance.post('/auth/refreshToken', body);
        if (response.status !== 200) {
          throw new Error('Failed to refresh access token');
        }
    
        // Extract the new access token from the response
        const access_token = await response.data.access_token;
    
        // Return the new access token
        return access_token;
      } catch (error) {
        // If an error occurs during the API call, throw it
        throw new Error('Error refreshing access token: ' + error.message);
      }
    };
      

    const verifyAccessToken = async (token) => {
      try {
        // Verify the access token (for example, by decoding it)
        const decodedToken = jwtDecode(token);
        // If verification succeeds, set the first name
        setFirstName(decodedToken.first_name);
      } catch (error) {
        // If verification fails, check if refresh token is valid
        if (refreshToken) {
          try {
            // Make an API call to refresh the access token using the refresh token
            const newAccessToken = await refreshAccessToken(refreshToken);
            localStorage.setItem('accessToken', newAccessToken);
            // Parse the new access token and set the first name
            const newDecodedToken = jwtDecode(newAccessToken);
            setFirstName(newDecodedToken.first_name);
          } catch (error) {
            // If refresh token is invalid, log the error and return null
            setFirstName(null);
          }
        } else {
          // If no refresh token is available, return null
          setFirstName(null);
        }
      }
    };

    if (accessToken) {
      verifyAccessToken(accessToken);
    } else {
      setFirstName(null);
    }
  }, []);
  return firstName;
};

export default useFirstName;
